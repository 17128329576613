.fade-enter {
	opacity: 0;
	transform: scale(1.5);
	z-index: 1;
  }
  .fade-enter.fade-enter-active {
	opacity: 1;
	transform: scale(1);
  
	transition: opacity 250ms ease-out, transform 300ms ease;
  }
  .fade-exit {
	opacity: 1;
	transform: scale(1);
  }
  .fade-exit.fade-exit-active {
	opacity: 0;
	transform: scale(0.5);
  
	transition: opacity 250ms ease-out, transform 300ms ease;
  }