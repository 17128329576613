button {
	background-color: var(--foreground);
	padding: 0.75em;
	text-align: center;
	margin: 0 1em;
	border-radius: 15px;
	transition: 0.05s linear 50ms;
	z-index: 0;
	color: var(--secondary);
	box-shadow: var(--boxShadow);
}

button:hover {
	transform: scale(1.1);
	opacity: 0.7 !important;
}

button:active {
	transform: scale(0.95);
}

button.MuiButtonBase-root {
	box-shadow: none;
}