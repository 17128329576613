/* Theme */
:root {
    --background: #ffc68f;
    --backgroundFaded: #ffc68f7a;
    --foreground: #fff3e3;
    --foregroundLightened: #fdf5ea;
    --primary: #8fafff;
    --primaryFaded: #8fafff9a;
    --secondary: #647bb3;
    --tertiary: #b39264;
    --boxShadow: 0.2em 0.2em 0.2em var(--secondary);
}

body,
button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Open Sans", sans-serif;
}

body {
    background-color: var(--background);
    background-image: url("./assets/backgroundTexture.png");
    background-attachment: fixed;
    margin: 0;
    margin-bottom: 1em;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    text-decoration: none;
    color: black;
}
a:hover {
    transform: scale(1.1);
    opacity: 0.7;
}
a:active {
    font-weight: bold;
    transform: scale(0.95);
}

a:visited {
    color: black;
}

.glass {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
    background: inherit;
    overflow: hidden;
}

.glass::before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    margin: -20px;
}

/* Scrollbar */
/* Chrome */
::-webkit-scrollbar {
    width: 1em;
    background: var(--secondaryFaded);
}

.scroll::-webkit-scrollbar-track {
    background: var(--foreground);
    border-radius: 0 100vw 100vw 0;
    max-height: 1em;
    height: 1em;
}

::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border: 0.25em solid var(--foreground);
    border-radius: 0 100vw 100vw 0;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(168, 168, 168);
}

::-webkit-scrollbar-thumb:active {
    background: rgb(107, 107, 107);
}

/* Firefox */
@supports (scrollbar-color: red blue) {
    * {
        scrollbar-color: var(--foreground) var(--background);
        scrollbar-width: thin;
    }
}

/* For international flag input */
/* .iti__flag {background-image: url("path/to/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
.iti__flag {background-image: url("path/to/flags@2x.png");}
} */
