#navbarWrapper {
	z-index: 5;
	width: 100vw;
	display: flex;
	align-content: center;
	justify-content: center;
	position: fixed;
}

.navlinks {
	padding: 1em;
	text-shadow: 1px 1px var(--secondary);
}

.active {
	border-bottom: 3px double black;
}

.transparent {
	transition: 1s ease-in-out;
	background-color: var(--primaryFaded) !important;
}

.opaque {
	transition: 1s ease-in-out;
	background-color: var(--primary) !important;
}

@media screen and (max-width: 480px) {
	.navlinks {
		font-size: 0.85em;
		padding: 0.5em;
	}
}