#contactPage {
	padding: 15vh 5em 0 5em;
	display: flex;
	justify-content: center;
	align-items: center;
}

#contactForm {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 25vw;
	border-radius: 5%;
	padding: 1em;
}

.input {
	margin: .75em !important;
}

.MuiFormControl-root.MuiTextField-root {
	border-radius: 0.5em;
	box-shadow: var(--boxShadow);
}

#message {
	height: 10em !important;
	width: 20vw !important;
	padding: .5em;
	overflow-y: scroll !important;
}

#thankYouMessage {
	padding: 5em;
	animation: fadeIn 2s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}

@media screen and (max-width: 1382px) {
	#contactPage {
		padding: 15vh 4em 0 4em
	}
	#contactForm {
		width: 30vw;
	}
	#thankYouMessage {
		padding: 1em;
	}
}

@media screen and (max-width: 992px) {
	#contactForm {
		width: 40vw;
	}
}

@media screen and (max-width: 768px) {
	#contactForm {
		width: 50vw;
	}
}

@media screen and (max-width: 480px) {
	#contactPage {
		padding: 15vh 1em 0 1em
	}
	#contactForm {
		width: 100%;
	}
}