#scrollArrow {
	position: fixed;
	visibility: hidden;
	right: 0;
	opacity: 0;
	box-shadow: var(--boxShadow);
	z-index: 100;
}

.show {
	animation: slideIn 1s ease forwards;
}

.hide {
	animation: slideOut 1s ease forwards;
}

@keyframes slideIn {
	0% {
		visibility: visible;
		bottom: -50%;
		opacity: 0;
	}
	75% {
		bottom: 5%;
	}
	100% {
		visibility: visible;
		bottom: 4%;
		opacity: 1;
	}
}

@keyframes slideOut {
	0% {
		visibility: visible;
		bottom: 4%;
		opacity: 1;
	}
	25% {
		bottom: 5%;
	}
	100% {
		opacity: 0;
		bottom: -8%;
		visibility: hidden;
	}
}