#carouselWrapper {
	height: 30em;
	min-height: 30em;
	overflow-y: clip;
	justify-content: center;
	align-items: center;
	border-bottom: 3px solid var(--secondary);
	border-top: 3px solid var(--secondary);
}

.carouselText {
	position: absolute;
	top: 25.5em;
	right: 1vw;
	color: white;
	padding: .75em;
	border-radius: 15em;
}

.carouselText::before {
	backdrop-filter: blur(8px);
}