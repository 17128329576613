#projectsPage {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 100px;
}

#buttons {
	margin-top: 30px;
}

#projectTitleWrapper {
	display: flex;
	flex-direction: column;
	width: 90vw;
	justify-content: center;
	align-items: center;
	margin: 1em;
}

.pageTitle {
	font-size: 2em;
}

#sizeNote {
	background-color: var(--secondary);
	color: var(--foreground);
	position: absolute;
	top: 15vh;
	margin: 0 auto;
	padding: 1em 1em 2em 1em;
	width: 40vw;
	border-radius: 15px;
	border: 2px solid black;
	text-align: center;
}

#questionMark {
	width: 1.5em;
	height: 1.5em;
}

.closeButton {
	width: 100%;
	text-align: right;
	padding-bottom: 0.25em;
	font-weight: bold;
	color: var(--foregroundLightened);
}

.closeButton:hover {
	opacity: 0.8;
}

.closeButton:active {
	opacity: 0.5;
}

@media screen and (max-width: 1382px) {
	#projectsPage {
		padding: 7vw 4em 0 4em;
	}
}

@media screen and (max-width: 768px) {
	#projectsPage {
		padding: 10vw 1em 0 1em;
	}
	#projectTitleWrapper {
		display: flex;
		width: 90vw;
		align-items: center;
		flex-direction: row;
	}
	#sizeNote {
		top: 18vh;
		width: 40vw;
	}
}

@media screen and (max-width: 480px) {
	#projectsPage {
		padding: 15vw 1em 0 1em;
	}
	.pageTitle {
		font-size: 1.8em;
	}
	.projectText {
		font-size: 1em;
	}
}

@media screen and (max-width: 380px) {
	.projectImage {
		width: 15em;
	}
}