#aboutPage {
    padding: 5em;
}

.parallax {
    /* parallax scrolling */
    margin: 6em auto 1em auto;
	min-height: 40em;
    width: 100%;
	border-radius: 1em;
	border: 8px double var(--secondary);
	background-image: url('../../assets/aboutPictures/beach.jpg');

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
	background-size: cover;
}

#aboutTitle {
	text-align: center;
	width: fit-content;
	padding: 0.5em;
	border-radius: 1em;
	border: 8px double var(--secondary);
	margin: -1.4em auto;
	font-weight: 400;
	font-size: 3.5em;
}

#parallax2 {
	background-image: url('../../assets/aboutPictures/beach.jpg');
}

.imgDesc {
	font-size: 3em;
	text-align: center;
}


.leftAligned,
.rightAligned,
#collage {
    display: flex;
    justify-content: center;
    align-items: center;
}

#rowOne {
    margin-bottom: -4em;
}

#projectTextLink {
	padding: 0;
}

#rowTwo {
    margin-top: -4em;
}

#rowThree {
    margin-top: -3em;
    margin-bottom: 4em;
}

#listWrapper {
    border: 8px double var(--secondary);
    border-radius: 5%;
    padding: 5px !important;
    max-height: 50%;
    background-color: var(--foreground);
}
.MuiImageList-root {
    border-radius: 5%;
    margin: 0px !important;
}

#meDuckyAndMouse,
#birdBirdCrew {
    margin: 2em;
}

.aboutText {
    width: 25vw;
    margin: 2em;
    padding: 1em;
    border-radius: 2em;
}

.MuiImageListItem-root {
    margin-left: -2px;
    margin-bottom: -2px;
    overflow-y: hidden;
}

@media screen and (max-width: 1120px) {
	#aboutPage {
		overflow-x: hidden;
	}
}

@media screen and (max-width: 992px) {
    .leftAligned,
    .rightAligned {
        flex-wrap: wrap;
    }
	#aboutTitle {
		font-size: 3em;
	}
    .aboutText {
        width: 90%;
    }
	.rightAligned {
        margin-top: 2em !important;
    }
    #rowThree {
        margin: 0 !important;
    }
    #imgContainer {
        display: block;
    }
    #birdBirdCrew {
        margin: 0;
    }
    #meDuckyAndMouse {
        display: none;
    }
    #collage {
        margin-top: 1em;
    }
    .MuiImageList-root {
        width: 65vw !important;
    }
}

@media screen and (max-width: 600px) {
	#aboutTitle {
		font-size: 2em;
	}
    .aboutText {
        width: 100%;
        margin: 2em 0;
    }
	#birdBirdCrew, #corvia {
		margin-left: 8px;
	}
    .MuiImageList-root {
        width: 80vw !important;
        height: auto;
    }
}

@media screen and (max-width: 510px) {
    #aboutPage {
        padding: 2em 1em;
    }
	.parallax {
		width: 97%;
	}
	#aboutTitle {
		font-size: 1.5em;
	}
    .MuiImageList-root {
        width: 80vw !important;
    }
    .images {
        width: 95vw !important;
    }
    .aboutText {
        width: 95%;
        margin: 2em 0;
    }
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform-origin: left;
		transform: translateX(-50%);
	}
	100% {
		opacity: 1;
		transform-origin: left;
		transform: translateX(-2%);
	}
}

@keyframes fadeOutRight {
	0% {
		opacity: 1;
		transform-origin: left;
		transform: translateX(-2%);
	}
	100% {
		opacity: 0;
		transform-origin: left;
		transform: translateX(-50%);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform-origin: right;
		transform: translateX(50%);
	}
	100% {
		opacity: 1;
		transform-origin: right;
		transform: translateX(-2%);
	}
}

@keyframes fadeOutLeft {
	0% {
		opacity: 1;
		transform-origin: right;
		transform: translateX(-2%);
	}
	100% {
		opacity: 0;
		transform-origin: right;
		transform: translateX(50%);
	}
}