#homePage {
	text-align: center;
}

.carouselImage, .slider {
	pointer-events: none;
}

.carouselImage {
	object-fit: cover;
	height: 30em
	
}

#descriptionWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1em;
}

#introWrapper {
	margin: 2em;
	padding: 2em;
	border-radius: 15em;
	/* min-width: 40vw; */
}

.title {
	font-size: 5em;
	font-weight: 100 !important;
	margin-bottom: 0;
}

.profession {
	margin-bottom: 1em;
}

.mainImages {
	min-width: 1em;
	max-width: 20em;
	border-radius: 25%;
	border: 8px double var(--secondary);
}

#socialsWrapper {
	width: 6em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 1em 0;
	margin-left: 2em;
	border-radius: 15em;
}

#socialsWrapper::before {
	backdrop-filter: blur(1px);
}

.socialLogo {
	width: 5em;
}

#github, #instagram {
	margin-left: 1em;
	margin-right: 1em;
}

#linkedin {
	width: 7em;
}


@media screen and (max-width: 992px) {

	#carouselWrapper {
		min-height: 20em;
		height: 20em;
	}

	.carouselText {
		top: 22em;
		font-size: 0.75em;
	}

	#introWrapper {
		padding: 3em;
	}
	
	#descriptionWrapper {
		flex-direction: column;
	}

	.title {
		font-size: 4em;
		margin-top: 0;
	}

	#socialsWrapper {
		flex-direction: row;
		width: 70vw;
		justify-content: space-evenly;
		margin-left: 0;
		margin-top: 1em;
	}

	#instagram {
		margin-right: 0;
	}
}

@media screen and (max-width: 480px) {
	.title {
		font-size: 3em;
		margin-top: 0;
	}

	#introWrapper {
		min-width: 0 !important;
		font-size: 85%;
	}

	.mainImages {
		width: 15em;
	}
	
	#socialsWrapper {
		width: 90vw;
	}

	.socialLogo {
		width: 3em;
	}

	#github {
		width: 2.8em;
	}
	
	#linkedin {
		width: 4em;
	}
}